import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import axios from 'axios';
import moment from 'moment-timezone';
import { apiUrl } from "../constants";
import { useNavigate } from 'react-router-dom';
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import ColumnChartGiveaway from '../components/chart/ColumnChartGiveaway'
import db from "firebase-config";
import PopupGiveawayDashboard from "../components/model/PopupGiveawayDashboard";



export default function GiveawayPage2() {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    const [chart_giveaway, setChart_giveaway] = useState([]);
    const [no_data, setNo_data] = useState(false);
    const [giveaway_dashboard, setgiveaway_dashboard] = useState({
        ACTUAL_PERCENT_GIVEAWAY: "",
        TARGET_GIVEAWAY: "",
        MIN_GIVEAWAY: ""
    });
    const [giveaway_dashboard_page1, setgiveaway_dashboard_page1] = useState({
        WARNING: "",
        PACK_SIZE_STD: "",
        SETTING_PACK_SIZE: ""
    });
    const [keeplive, setkeeplive] = useState([]);
    const [check_list, setCheck_list] = useState([]);
    /*const today = new Date();
    const hourago = new Date(today.getTime() - (1000 * 60 * 60));
    const timestr_chart = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');*/





    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("meskr_giveaway_5_min_timeframe")
            //.where("TIMESTAMP", ">=", timestr_chart)
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setChart_giveaway(getPostFromFirebase);
                function groupBy(getPostFromFirebase, property) {
                    return getPostFromFirebase.reduce(function (memo, x) {
                        if (!memo[x[property]]) {
                            memo[x[property]] = [];
                        }
                        memo[x[property]].push(x);
                        return memo;
                    }, {});
                }
            
                //group by name
                const grouped = groupBy(getPostFromFirebase, "TIMESTAMP");
                const keys = Object.keys(grouped);
                var output = [];
            
                //loop keys
                keys.forEach(key => {
                    //merge using reduce
                    const out = grouped[key].reduce((current) => {
                        return {
                            GIVEAWAY_PERCENT: current.GIVEAWAY_PERCENT,
                            TIMESTAMP: current.TIMESTAMP,
            
                        }
                    });
                    output.push(out);
                });

                sessionStorage.setItem('time_giveaway', output.map(item =>
                    moment(new Date(item.TIMESTAMP)).tz('Asia/Bangkok').format('HH:mm')
                ));
                sessionStorage.setItem('value_giveaway', output.map(i => i.GIVEAWAY_PERCENT));
                


            });

        // return cleanup function
        return () => subscriber();
    }, [chart_giveaway]);



    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("meskr_keeplive")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setkeeplive(getPostFromFirebase);
                let mul1 = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.Multihead.Multihead1.Line7_1.0NoError"));
                sessionStorage.setItem('multihead1', mul1?.VALUE);
                let mul2 = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.Multihead.Multihead2.Line7_2.0NoError"));
                sessionStorage.setItem('multihead2', mul2?.VALUE);
                let c_w = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.IFORT.CW.Line7.Status"));
                sessionStorage.setItem('c_w', c_w?.VALUE);
                let x_ray = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.IFORT.X_RAY.Line7.Status"));
                sessionStorage.setItem('x_ray', x_ray?.VALUE);
                let m_d = getPostFromFirebase.find((user) => user.ID.includes("Further.4117.IFORT.MD_B.Line7.Status"));
                sessionStorage.setItem('m_d', m_d?.VALUE);

            });

        // return cleanup function
        return () => subscriber();
    }, [keeplive]);



    const multihead1 = keeplive.find((user) => user.ID.includes("Further.4117.Multihead.Multihead1.Line7_1.0NoError"));
    const multihead2 = keeplive.find((user) => user.ID.includes("Further.4117.Multihead.Multihead2.Line7_2.0NoError"));
    const check_weihgt = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.CW.Line7.Status"));
    const xray = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.X_RAY.Line7.Status"));
    const md = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.MD_B.Line7.Status"));

    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("meskr_giveaway_tag_list")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });

                setCheck_list(getPostFromFirebase);

                let permiss = getPostFromFirebase.find((user) => user.TAG.includes("_AdvancedTags.4117.GiveAway.Interlock.Permissive_1"));
                sessionStorage.setItem('permissive', permiss?.VALUE);

            });

        // return cleanup function
        return () => subscriber();
    }, [check_list]);

    const permissive = check_list.find((user) => user.TAG.includes("_AdvancedTags.4117.GiveAway.Interlock.Permissive_1"));



    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${apiUrl.GIVEAWAY_DASHBOARG_PAGE2}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        sessionStorage.setItem('actual_percent_giveaway', response.data[0].ACTUAL_PERCENT_GIVEAWAY);
                        sessionStorage.setItem('target_giveaway', response.data[0].TARGET_GIVEAWAY);
                        sessionStorage.setItem('min_giveaway', response.data[0].MIN_GIVEAWAY);
                        setgiveaway_dashboard({
                            ACTUAL_PERCENT_GIVEAWAY: response.data[0].ACTUAL_PERCENT_GIVEAWAY,
                            TARGET_GIVEAWAY: response.data[0].TARGET_GIVEAWAY,
                            MIN_GIVEAWAY: response.data[0].MIN_GIVEAWAY,
                        })
                        setNo_data(1)

                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }



    }, []);

    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${apiUrl.GIVEAWAY_DASHBOARG_PAGE1}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        sessionStorage.setItem('warning', response.data[0].WARNING);
                        setgiveaway_dashboard_page1({
                            WARNING: response.data[0].WARNING,
                            SETTING_PACK_SIZE: response.data[0].SETTING_PACK_SIZE,
                            PACK_SIZE_STD: response.data[0].PACK_SIZE_STD,
                        })
                        setNo_data(1)

                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }



    }, []);

    //console.log((((giveaway_dashboard_page1.SETTING_PACK_SIZE - giveaway_dashboard_page1.PACK_SIZE_STD)/giveaway_dashboard_page1.PACK_SIZE_STD) * 100) * 2)


    //group an array by property (https://stackoverflow.com/questions/72204548/loop-through-an-array-of-objects-find-duplicate-objects-by-id-add-their-values)
    function groupBy(chart_giveaway, property) {
        return chart_giveaway.reduce(function (memo, x) {
            if (!memo[x[property]]) {
                memo[x[property]] = [];
            }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }

    //group by name
    const grouped = groupBy(chart_giveaway, "TIMESTAMP");
    const keys = Object.keys(grouped);
    var output = [];

    //loop keys
    keys.forEach(key => {
        //merge using reduce
        const out = grouped[key].reduce((current) => {
            return {
                GIVEAWAY_PERCENT: current.GIVEAWAY_PERCENT,
                TIMESTAMP: current.TIMESTAMP,

            }
        });
        output.push(out);
    });


    //// convert date time format inside array (https://stackoverflow.com/questions/66385908/reactjs-how-to-convert-the-date-format-inside-an-array-of-hash)
    const time_giveaway = output.map(item =>
        moment(new Date(item.TIMESTAMP)).tz('Asia/Bangkok').format('HH:mm')
    )

    const value_giveaway = output.map(i => i.GIVEAWAY_PERCENT == null ? 0 : i.GIVEAWAY_PERCENT);

    ///// store data in sessionStorage
    const array_value = sessionStorage.getItem('value_giveaway') == null ? [0] : sessionStorage.getItem('value_giveaway').split(",").map(Number);

    const array_time = sessionStorage.getItem('time_giveaway') == null ? [0] : sessionStorage.getItem('time_giveaway').split(",")


    const show_value = value_giveaway == 0 ? array_value : value_giveaway;

    const show_time = time_giveaway == 0 ? array_time : time_giveaway;

    //console.log(giveaway_dashboard.MIN_GIVEAWAY)

    setTimeout(() => {
        (permissive?.VALUE == undefined ? sessionStorage.getItem('permissive') == 'true' : permissive?.VALUE) == true ? navigate('/giveaway/p1') : navigate('/status_giveaway_function')
    }, 30000);
    






    return (
        <>
            {giveaway_dashboard_page1.WARNING == 'ไม่ตรงแผนการผลิต' || sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' || multihead1?.VALUE == false || multihead2?.VALUE == false || check_weihgt?.VALUE == 0 || xray?.VALUE == 0 || md?.VALUE == 0 || (sessionStorage.getItem('multihead1') == 'true') == false || (sessionStorage.getItem('multihead2') == 'true') == false || Number(sessionStorage.getItem('c_w')) == 0 || Number(sessionStorage.getItem('x_ray')) == 0 || Number(sessionStorage.getItem('m_d')) == 0 ? <PopupGiveawayDashboard /> :
                <Helmet>
                    <title>Giveaway Page 2| MES Korat Fur2</title>
                </Helmet>
            }
            <Grid
                container spacing={1}
                direction="row"
                alignItems="top"
                justifyContent="center"
                style={{ minHeight: '78vh', maxHeight: '200vh', flexGrow: 1, paddingLeft: 40, paddingTop: 10 }}
            >
                {/* Top row */}

                <Grid container sx={{ pt: 0 }} spacing={1} style={{ position: "relative", left: "0vw", top: "0vw" }}>
                    <Grid id="top-row" container xs={12} md={12} lg={12} xl={12} style={{ position: "relative", left: "0vw" }}>
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: 48, position: "relative", left: "0vw", top: "0vw" }} textAlign="left" >
                            Realtime Giveaway (%)
                        </Typography>
                    </Grid>
                </Grid>


                <Grid container spacing={3} style={{ position: "relative", top: '-3vw', left: '0vw' }}>
                    <Grid id="top-row" container xs={9} md={9} lg={4} xl={4} style={{ position: "relative", padding: 3 }}>
                        <ColumnChartGiveaway
                            value={show_value}
                            time={show_time}
                            setting={Number(giveaway_dashboard_page1.SETTING_PACK_SIZE)}
                            std={Number(giveaway_dashboard_page1.PACK_SIZE_STD)}
                            target={no_data == 1 ? (giveaway_dashboard.TARGET_GIVEAWAY == undefined ? 0 : (giveaway_dashboard.TARGET_GIVEAWAY == 'None' ? 0 : giveaway_dashboard.TARGET_GIVEAWAY)) : sessionStorage.getItem('target_giveaway') == 'undefined' ? 0 : (sessionStorage.getItem('target_giveaway') == 'None' ? 0 : sessionStorage.getItem('target_giveaway'))}

                        />

                    </Grid>

                    <Grid item xs={3} md={3} lg={3} xl={3} textAlign="left" style={{ position: "relative", top: '0vw' }}>
                        <Typography style={{ color: colors.black[100], fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                            &nbsp;&nbsp;Best Practice
                        </Typography>
                        <Typography style={{ color: '#4AC6B1', fontSize: 32, fontWeight: 'bold', position: "relative", top: "-2vw" }} >
                            &nbsp;&nbsp;{no_data == 1 ? (giveaway_dashboard.MIN_GIVEAWAY == undefined ? '-' : (giveaway_dashboard.MIN_GIVEAWAY == 'None' ? '-' : Number(giveaway_dashboard.MIN_GIVEAWAY))) : sessionStorage.getItem('min_giveaway') == null ? '-' : (sessionStorage.getItem('min_giveaway') == 'undefined' ? '-' : (sessionStorage.getItem('min_giveaway') == 'None' ? '-' : Number(sessionStorage.getItem('min_giveaway'))))}%
                        </Typography>
                        <Typography style={{ color: colors.black[100], fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                            &nbsp;&nbsp;Target
                        </Typography>
                        <Typography style={{ color: '#4AC6B1', fontSize: 32, fontWeight: 'bold', position: "relative", top: "-2vw" }} >
                            &nbsp;&nbsp;{no_data == 1 ? (giveaway_dashboard.TARGET_GIVEAWAY == undefined ? '-' : (giveaway_dashboard.TARGET_GIVEAWAY == 'None' ? '-' : Number(giveaway_dashboard.TARGET_GIVEAWAY))) : sessionStorage.getItem('target_giveaway') == null ? '-' : (sessionStorage.getItem('target_giveaway') == 'undefined' ? '-' : (sessionStorage.getItem('target_giveaway') == 'None' ? '-' : Number(sessionStorage.getItem('target_giveaway'))))}%
                        </Typography>
                        <Typography style={{ color: colors.black[100], fontSize: 22, position: "relative", top: "0vw", fontWeight: 'bold' }} >
                            &nbsp;&nbsp;Actual
                        </Typography>
                        <Typography style={{ color: '#4AC6B1', fontSize: 32, fontWeight: 'bold', position: "relative", top: "-2vw" }} >
                            &nbsp;&nbsp;{no_data == 1 ? (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == undefined ? '-' : (giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY == 'None' ? '-' : Number(giveaway_dashboard.ACTUAL_PERCENT_GIVEAWAY))) : sessionStorage.getItem('actual_percent_giveaway') == null ? '-' : (sessionStorage.getItem('actual_percent_giveaway') == 'undefined' ? '-' : (sessionStorage.getItem('actual_percent_giveaway') == 'None' ? '-' : Number(sessionStorage.getItem('actual_percent_giveaway'))))}%
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

        </>
    );
}