import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import moment from 'moment-timezone';
import { apiUrl } from "../constants";
import { useNavigate } from 'react-router-dom';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import db from "firebase-config";
import HeaderDetail from "../components/header/HeaderDetail";
import LineChartHeatingYield from "../components/chart/LineChartHeatingYield";
import Watch from "../assets/images/watch.png";
import Temperature from "../assets/images/temperature.png";

const ItemHeatingYield = styled(Paper)(({ theme }) => ({
    backgroundColor: '#18443c',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    //border: '0.5px dashed #4A4A49',
    borderRadius: 0,
    // height: '100%'

}));

///linear-gradient color to Material UI Grid background(https://stackoverflow.com/questions/48849340/how-to-add-linear-gradient-color-to-material-ui-chip-background)
const ItemChart = styled(Paper)(({ theme }) => ({
    background: 'linear-gradient(#0c6c2c, #448c26, #e4ac0c, #cc741c, #940404)',
    padding: theme.spacing(0.8),
    textAlign: 'center',
    //border: '0.5px dashed #4A4A49',
    borderRadius: 0,
    // height: '100px'

}));

const ItemKG = styled(Paper)(({ theme }) => ({
    backgroundColor: '#302c2c',
    padding: theme.spacing(0.5),
    textAlign: 'center',
    //border: '0.5px dashed #4A4A49',
    borderRadius: 15,
    // height: '50%',
}));





export default function HeatingYield() {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    const today = new Date();
    const hourago = new Date(today.getTime());
    const timestr = moment(hourago).tz('Asia/Bangkok').format('DD MMM YY');
    const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');



    return (
        <>
            <Helmet>
                <title>Heating Yield | MES Korat Fur2</title>
            </Helmet>
            <Grid item xs={12}>
                <HeaderDetail
                    line={'Line 7'}
                    sku={'MN-F-3034'}
                    icon={<LightModeOutlinedIcon style={{ color: '#ffffff' }} />}
                />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 5 }}>
                <Grid container rowSpacing={0.5} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                    <Grid item xs={7} >
                        <Grid item xs={12} textAlign="center" style={{ paddingTop: 0, paddingLeft: 10 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                <Typography style={{ color: colors.black[100], fontSize: 28, fontWeight: 'bold', lineHeight: "10vh", position: "relative", top: "0vw" }} >
                                    Heating Yield&nbsp;
                                </Typography>
                                <Typography style={{ color: colors.black[100], fontSize: 20, fontWeight: 'bold', lineHeight: "10vh", paddingTop: 1 }} >
                                    (%)
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} textAlign="center" style={{ paddingTop: 0, paddingLeft: 10 }}>
                            <ItemHeatingYield>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                    <Grid item xs={3.9} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.grey[100], fontSize: 24, fontWeight: 'bold', lineHeight: "8vh" }} >
                                                Std.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.black[100], fontSize: 38, fontWeight: 'bold', lineHeight: "10vh" }} >
                                                80-88
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={0.1} style={{ borderLeft: "1px dashed #000000", height: '18vh' }} >

                                    </Grid>

                                    <Grid item xs={3.9} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.grey[100], fontSize: 24, fontWeight: 'bold', lineHeight: "8vh" }} >
                                                Avg. Actual
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: '#b4a41c', fontSize: 38, fontWeight: 'bold', lineHeight: "10vh" }} >
                                                85.32
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={0.1} style={{ borderLeft: "1px dashed #000000", height: '18vh' }} >

                                    </Grid>

                                    <Grid item xs={3.9} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.grey[100], fontSize: 24, fontWeight: 'bold', lineHeight: "8vh" }} >
                                                Best Practice
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.black[100], fontSize: 38, fontWeight: 'bold', lineHeight: "10vh" }} >
                                                87.30
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </ItemHeatingYield>
                        </Grid>

                        <Grid item xs={12} textAlign="center" style={{ paddingTop: 5, paddingLeft: 10 }} >
                            <ItemChart style={{height: '48vh'}}>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="right" >
                                    <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', lineHeight: "8vh" }} >
                                        Realtime 1 Hr.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="left" >

                                </Grid>
                                <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                    <LineChartHeatingYield
                                        value={[82.2, 84.3, 85.2, 82.3, 84.3, 87.2, 83.2, 81.2, 83.2, 84.5, 86.5]}
                                        time={['08:25', '08:30', '08:35', '08:40', '08:45', '08:50', '08:55', '09:00', '09:05', '09:10', '09:15']}
                                        target={87.30}

                                    />
                                </Grid>

                            </ItemChart>

                        </Grid>
                    </Grid>

                    <Grid item xs={5} >
                        <Grid item xs={12} textAlign="center" style={{ paddingRight: 10 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                <Typography style={{ color: colors.black[100], fontSize: 28, fontWeight: 'bold', lineHeight: "10vh", position: "relative", top: "0vw" }} >
                                    FG&nbsp;
                                </Typography>
                                <Typography style={{ color: colors.black[100], fontSize: 20, fontWeight: 'bold', lineHeight: "10vh", paddingTop: 1 }} >
                                    (kg)
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} textAlign="center" style={{ paddingRight: 10, paddingLeft: 10 }}>
                            <ItemKG style={{height: '33vh'}}>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                    <Grid item xs={5.9} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.grey[100], fontSize: 24, fontWeight: 'bold', lineHeight: "10vh" }} >
                                                Actucl
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.black[100], fontSize: 38, fontWeight: 'bold', lineHeight: "12vh" }} >
                                                4,500
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                <Typography style={{ color: colors.grey[100], fontSize: 16, fontWeight: 'bold', lineHeight: "8vh", paddingTop: 2 }} >
                                                    Target&nbsp;
                                                </Typography>
                                                <Typography style={{ color: colors.grey[100], fontSize: 24, fontWeight: 'bold', lineHeight: "8vh" }} >
                                                    5,000
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={0.2} style={{ borderLeft: "1px dashed #c2c2c2", height: '27vh', position: "relative", top: "1vw" }} >

                                    </Grid>

                                    <Grid item xs={5.9} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.grey[100], fontSize: 24, fontWeight: 'bold', lineHeight: "10vh" }} >
                                                Benefit
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: '#2e9281', fontSize: 38, fontWeight: 'bold', lineHeight: "12vh" }} >
                                                +  100
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ItemKG>
                        </Grid>

                        <Grid item xs={12} textAlign="center" style={{ paddingTop: 18, paddingRight: 10, paddingLeft: 10 }}>
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                <Grid item xs={6} >
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                        <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', lineHeight: "8vh" }} >
                                            Feed rate
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}  textAlign="center"  >
                                        <ItemKG style={{height: '25vh'}}>
                                            <Grid item xs={12} textAlign="center" >
                                                <Typography style={{ color: colors.black[100], fontSize: 36, fontWeight: 'bold', lineHeight: "12vh" }} >
                                                    730
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: colors.grey[100], fontSize: 16, fontWeight: 'bold', lineHeight: "9vh", paddingTop: 2 }} >
                                                        Std.&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.black[100], fontSize: 20, fontWeight: 'bold', lineHeight: "9vh" }} >
                                                        5,000&nbsp;
                                                    </Typography>
                                                    <Typography style={{ color: colors.grey[100], fontSize: 16, fontWeight: 'bold', lineHeight: "9vh", paddingTop: 2 }} >
                                                        kg/hr
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </ItemKG>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6} style={{ paddingLeft: 5 }}>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                        <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', lineHeight: "8vh" }} >
                                            Machine
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                        <ItemKG style={{height: '25vh'}}>
                                            <Grid item xs={12} md={12} lg={12} xl={12} >
                                                <table style={{ width: '50%' }}>
                                                    <tbody>
                                                        <tr >
                                                            <td style={{ width: '30%', lineHeight: "8vh" }} align="right">
                                                                <img src={Temperature} height="25vh" />
                                                            </td>
                                                            <td style={{ width: '20%', color: '#2e9281', fontSize: 30, fontWeight: 'bold', lineHeight: "8vh" }} align="right">
                                                                170
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.black[100], fontSize: 20, fontWeight: 'bold', lineHeight: "8vh" }} align="left">
                                                                ํC
                                                            </td>
                                                        </tr>

                                                        <tr >
                                                            <td style={{ width: '40%', lineHeight: "8vh" }} align="right">
                                                                <img src={Watch} height="25vh" />
                                                            </td>
                                                            <td style={{ width: '20%', color: '#2e9281', fontSize: 35, fontWeight: 'bold', lineHeight: "8vh" }} align="right">
                                                                8
                                                            </td>
                                                            <td style={{ width: '20%', color: colors.black[100], fontSize: 20, fontWeight: 'bold', lineHeight: "8vh", paddingTop: 6 }} align="left">
                                                                min
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Grid>
                                        </ItemKG>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid >
            </Grid >
        </>
    );
}
